import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Contacts } from '@ionic-native/contacts/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {HammerGestureConfig,HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { SocialGestureDirective } from './social-gestures.directive';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';

export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'pan-x'
    });

    return mc;
  }
}

@NgModule({
  declarations: [AppComponent, SocialGestureDirective],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(),    TranslateModule.forRoot(), AppRoutingModule, HammerModule, ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: environment.production,
  // Register the ServiceWorker as soon as the app is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
})],
  providers: [
    // StatusBar,
    Contacts,HammerModule,
    // SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  exports: [
    SocialGestureDirective
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
